import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import graph from 'fbgraph';
import RSVP from 'rsvp';

import config from 'later/config/environment';
import createIgCommentFromGraph from 'later/utils/formatters/ig-comment-from-graph';
import createIgPostFromGraph from 'later/utils/formatters/ig-post-from-graph';
import graphCall from 'later/utils/graph-call';
import createIgUserFromGraph from 'shared/utils/formatters/ig-user-from-graph';

import type { Hashtag } from 'collect/types/hashtag-search';
import type IgComment from 'later/models/ig-comment';
import type IgPost from 'later/models/ig-post';
import type SocialProfileModel from 'later/models/social-profile';
import type AuthService from 'later/services/auth';
import type IgUser from 'shared/models/ig-user';
import type { EmptyObject, Maybe } from 'shared/types';
import type {
  InstagramPagination,
  InstagramUserInfo,
  InstagramCommentReply,
  InstagramGraphComment,
  InstagramGraphMediaResponse,
  RecentInstagramMediaGraph
} from 'shared/types/instagram';
import type { JsonValue } from 'type-fest';

export interface FetchMediaParams {
  count?: number;
  'jolteon-refresh-cache'?: boolean;
}

export interface FetchHashtagParams {
  after?: string;
  limit?: number;
}

export type GraphCallParams = {
  method?: string;
  token?: string | null;
  backupToken?: string | null;
  version?: string | null;
};

interface InstagramGraphError {
  code: number;
}

export interface InstagramPosts {
  pagination: InstagramPagination;
  posts: IgPost[];
}

interface InstagramGraphError {
  code: number;
}

export default class InstagramGraphService extends Service {
  @service declare auth: AuthService;

  /**
   * Code from possible error response following request to Graph API
   */
  errorCode?: number;
  private isInstagramApi = false;

  constructor(args: EmptyObject) {
    super(args);
    graph.setVersion(config.APP.facebookGraphVersion);
  }

  /**
   * Sets the FbGraph User Access token that will be used
   * in all subsequent requests to the Graph API
   */
  setAccessToken(userAccessToken: string): void {
    graph.setAccessToken(userAccessToken);
  }

  /**
   * Gets the FbGraph Access token that will be used
   * in all subsequent requests to the Graph API
   */
  getAccessToken(): Maybe<string> {
    return graph.getAccessToken();
  }

  setIsInstagramApi(value: boolean): void {
    this.isInstagramApi = value;
  }

  /**
   * Fetches Recent Media (Posts) for specified User via the Instagram API.
   */
  fetchRecentMedia(socialProfile: SocialProfileModel, params: FetchMediaParams): Promise<RecentInstagramMediaGraph> {
    // pass it in as part of the url
    const fields = [
      'caption',
      'comments_count',
      'ig_id',
      'like_count',
      'media_product_type',
      'media_type',
      'media_url',
      'permalink',
      'thumbnail_url',
      'timestamp'
    ].join(',');
    const limit = 30;
    const url = this.#getRequestUrl(
      socialProfile.get('businessAccountId') + `/media?fields=${fields}&limit=${limit}`,
      socialProfile
    );
    return new RSVP.Promise((resolve, reject) => {
      graphCall(url, params, {
        token: this.#getGraphAccessToken(socialProfile),
        backupToken: socialProfile.get('businessAccountToken')
      })
        .then((response) => {
          if (isEmpty(response.data)) {
            // Note: If no posts are found, we are forcing an error to fall back to old IG API
            const errorMessage = 'Error: Graph API response contains no media.';
            reject(errorMessage);
          } else {
            const posts = response.data.map((rawPost: InstagramGraphMediaResponse) =>
              createIgPostFromGraph(rawPost, socialProfile)
            );
            resolve({
              pagination: response.paging,
              posts
            });
          }
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Fetches specified Instagram Media (Post) via the Instagram API.
   *
   * @param socialProfile The socialProfile making the request
   * @param mediaId instagram media id to fetch
   * @param params Endpoint specific parameters
   *
   * @returns Formatted Instagram Post
   */
  fetchMedia(socialProfile: SocialProfileModel, mediaId: string, params: FetchMediaParams = {}): Promise<IgPost> {
    return new RSVP.Promise((resolve, reject) => {
      // pass it in as part of the url
      const fields = [
        'caption',
        'comments_count',
        'like_count',
        'media_url',
        'thumbnail_url',
        'media_type',
        'timestamp',
        'permalink',
        !socialProfile.isInstagramLogin ? 'ig_id' : undefined
      ]
        .filter(Boolean)
        .join(',');

      graphCall(this.#getRequestUrl(`${mediaId}?fields=${fields}`, socialProfile), params, {
        token: this.#getGraphAccessToken(socialProfile),
        backupToken: socialProfile.get('businessAccountToken')
      })
        .then((response) => {
          const post = createIgPostFromGraph(response, socialProfile);
          resolve(post);
        })
        .catch((error) => reject(error));
    });
  }

  fetchSelf(socialProfile: SocialProfileModel): Promise<IgUser> {
    const businessAccountId = socialProfile.get('businessAccountId');
    const fields = [
      'id',
      'name',
      'media_count',
      'profile_picture_url',
      'username',
      'followers_count',
      'follows_count',
      'website'
    ].join(',');
    return new RSVP.Promise((resolve, reject) => {
      graphCall(
        this.#getRequestUrl(`/${businessAccountId}`, socialProfile),
        { fields },
        {
          token: this.#getGraphAccessToken(socialProfile),
          backupToken: socialProfile.businessAccountToken
        }
      )
        .then((response) => {
          const igUser = createIgUserFromGraph(response, socialProfile);
          resolve(igUser);
        })
        .catch((error) => reject(error));
    });
  }

  fetchHashtag(socialProfile: SocialProfileModel, name: string): Promise<Hashtag> {
    return new RSVP.Promise((resolve, reject) => {
      const fields = ['id', 'name'].join(',');
      const token = this.#getGraphAccessToken(socialProfile);
      const url = this.#getRequestUrl(
        `ig_hashtag_search?fields=${fields}&user_id=${socialProfile.get('businessAccountId')}&q=${name}`,
        socialProfile
      );
      graphCall(url, {}, { token, backupToken: socialProfile.businessAccountToken })
        .then((response) => resolve(response.data[0]))
        .catch((error) => {
          this.set('errorCode', error.code);
          reject(error);
        });
    });
  }

  fetchHashtagById(
    hashtagId: string,
    socialProfile: SocialProfileModel,
    params: FetchHashtagParams = {}
  ): Promise<Hashtag> {
    return new RSVP.Promise((resolve, reject) => {
      const fields = ['id', 'name'].join(',');
      const url = this.#getRequestUrl(`${hashtagId}?fields=${fields}`, socialProfile);
      graphCall(url, params, {
        token: this.#getGraphAccessToken(socialProfile),
        backupToken: socialProfile.businessAccountToken
      })
        .then((response) => resolve(response))
        .catch((error) => {
          this.set('errorCode', error.code);
          reject(error);
        });
    });
  }

  fetchHashtagMedia(
    endpoint = 'recent_media',
    socialProfile: SocialProfileModel,
    hashtagId: string,
    params: FetchHashtagParams = { limit: 50 }
  ): Promise<InstagramPosts> {
    return new RSVP.Promise((resolve, reject) => {
      const fields = [
        'caption',
        'comments_count',
        'id',
        'like_count',
        'media_type',
        'media_url',
        'permalink',
        'children{media_type,media_url}'
      ].join(',');
      const url = this.#getRequestUrl(
        `${hashtagId}/${endpoint}?user_id=${socialProfile.get('businessAccountId')}&fields=${fields}`,
        socialProfile
      );

      graphCall(url, params, {
        token: this.#getGraphAccessToken(socialProfile)
      })
        .then((response) => {
          // Note: api does not return media_url for copyrighted content
          const posts = response.data
            .filter((m: InstagramGraphMediaResponse) => m.children || m.media_url)
            .map((m: InstagramGraphMediaResponse) => createIgPostFromGraph(m, socialProfile));
          resolve({
            pagination: response.paging,
            posts
          });
        })
        .catch((error) => {
          this.set('errorCode', error.code);
          reject(error);
        });
    });
  }

  fetchRecentHashtag(
    socialProfile: SocialProfileModel,
    params: FetchHashtagParams = { limit: 50 }
  ): Promise<Hashtag[]> {
    return new RSVP.Promise((resolve, reject) => {
      const fields = ['id', 'name'].join(',');
      const url = this.#getRequestUrl(
        `${socialProfile.businessAccountId}/recently_searched_hashtags?fields=${fields}`,
        socialProfile
      );
      graphCall(url, params, {
        token: this.#getGraphAccessToken(socialProfile)
      })
        .then((res: { data: Hashtag[] }) => resolve(res.data))
        .catch((err: InstagramGraphError) => {
          this.set('errorCode', err.code);
          reject(err);
        });
    });
  }

  /**
   * Fetches a business user for the specified username via the Business Discovery API.
   */
  async fetchBusinessUser(socialProfile: SocialProfileModel, username?: string): Promise<InstagramUserInfo> {
    const { businessAccountToken, businessAccountId } = socialProfile;
    const token = businessAccountToken || this.auth.currentUserModel.facebookToken;
    const params = {};
    const fields = [
      'followers_count',
      'media_count',
      'biography',
      'name',
      'profile_picture_url',
      'website',
      'username'
    ];
    const url = this.#getRequestUrl(
      `/${businessAccountId}?fields=business_discovery.username(${username}){${fields}}`,
      socialProfile
    );
    const response = await graphCall(url, params, { token, backupToken: businessAccountToken });
    const userInfo = { ...response.business_discovery };
    return userInfo;
  }

  /**
   * Fetches all Instagram Media (Posts) for the specified username via the Business Discovery API.
   */
  fetchRecentMediaByUsername(
    socialProfile: SocialProfileModel,
    username?: string,
    afterCursor = ''
  ): Promise<{ posts: Maybe<IgPost[]>; userInfo: InstagramUserInfo; pagination?: InstagramPagination }> {
    return new RSVP.Promise((resolve, reject) => {
      const { businessAccountToken, businessAccountId } = socialProfile;
      const token = businessAccountToken || this.auth.currentUserModel.facebookToken;
      const params = {};
      const limit = 30;
      const fields = [
        'followers_count',
        'media_count',
        'biography',
        'name',
        'profile_picture_url',
        'website',
        'username'
      ];
      const mediaFields = [
        'id',
        'caption',
        'media_type',
        'media_url',
        'permalink',
        'comments_count',
        'like_count',
        'children{media_type,media_url}',
        'username',
        'thumbnail_url'
      ].join(',');

      if (isEmpty(afterCursor)) {
        fields.push(`media.limit(${limit}){${mediaFields}}`);
        fields.join(',');
      } else {
        fields.push(`media.after(${afterCursor}).limit(${limit}){${mediaFields}}`);
        fields.join(',');
      }
      const url = this.#getRequestUrl(
        `/${businessAccountId}?fields=business_discovery.username(${username}){${fields}}`
      );
      graphCall(url, params, { token, backupToken: businessAccountToken })
        .then((response) => {
          if (isEmpty(response.business_discovery.media)) {
            const userInfo = { ...response.business_discovery };
            const posts = undefined;
            resolve({
              posts,
              userInfo
            });
          } else {
            const posts = response.business_discovery.media.data.map((rawPost: InstagramGraphMediaResponse) =>
              createIgPostFromGraph(rawPost, socialProfile)
            );
            const userInfo = { ...response.business_discovery };
            delete userInfo.media;
            resolve({
              pagination: response.business_discovery.media.paging,
              posts,
              userInfo
            });
          }
        })
        .catch((error) => reject(error));
    });
  }

  fetchTaggedMedia(socialProfile: SocialProfileModel, params: FetchMediaParams): Promise<InstagramPosts> {
    return new RSVP.Promise((resolve, reject) => {
      const fields = [
        'caption',
        'comments_count',
        'id',
        'like_count',
        'media_type',
        'media_url',
        'permalink',
        'timestamp',
        'username',
        'children{media_type,media_url}'
      ].join(',');
      const url = this.#getRequestUrl(`${socialProfile.businessAccountId}/tags?fields=${fields}`, socialProfile);
      graphCall(url, params, {
        token: this.#getGraphAccessToken(socialProfile),
        backupToken: socialProfile.businessAccountToken
      })
        .then((response) => {
          // api does not return media_url for copyrighted content
          const posts = response.data
            .filter((post: { children?: JsonValue; media_url?: string }) => post.children || post.media_url)
            .map((post: InstagramGraphMediaResponse) => createIgPostFromGraph(post, socialProfile));
          resolve({
            pagination: response.paging,
            posts
          });
        })
        .catch((error) => {
          this.set('errorCode', error.code);
          reject(error);
        });
    });
  }

  /**
   * Fetches comments on a specified Instagram post.
   */
  fetchMediaComments(
    socialProfile: SocialProfileModel,
    media: IgPost,
    pagingInfo: Maybe<string>
  ): Promise<IgComment[]> {
    return new RSVP.Promise((resolve, reject) => {
      const commentFields = ['id', 'like_count', 'text', 'timestamp', 'username'];
      const fields = [commentFields.join(','), 'media', `replies{${commentFields.join(',')}}`].join(',');
      const limit = 50;
      const params = pagingInfo ? { after: pagingInfo } : {};
      const token = this.#getGraphAccessToken(socialProfile);

      graphCall(this.#getRequestUrl(media.id + `/comments?fields=${fields}&limit=${limit}`, socialProfile), params, {
        token,
        backupToken: socialProfile.get('businessAccountToken')
      })
        .then((res: { data: InstagramGraphComment[]; paging: InstagramPagination }) => {
          const uniqueCommentIds = [...new Set(res.data.map((rawComment) => rawComment.id))];
          const comments: IgComment[] = [];

          for (const uniqueId of uniqueCommentIds) {
            const comment = res.data.find((responseObj) => responseObj.id === uniqueId);
            if (comment) {
              const igComment = createIgCommentFromGraph(comment, socialProfile, media.id);
              comments.push(igComment);
              igComment.replies.forEach((reply) => {
                comments.push(reply);
              });
            }
          }
          const hasPagingInfo = Boolean(res.paging && res.paging.cursors);
          media.set('pagingInfo', hasPagingInfo ? res.paging.cursors.after : undefined);

          return resolve(comments);
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Posts a reply to a specified Instagram comment.
   */
  postReply(
    commentId: string,
    message: string,
    isReply = false,
    socialProfile: SocialProfileModel
  ): Promise<InstagramCommentReply> {
    const endpoint = isReply ? 'replies' : 'comments';
    return new RSVP.Promise((resolve, reject) => {
      const fields = [
        'id',
        'like_count',
        'text',
        'timestamp',
        'username',
        'media{id,caption,comments_count,media_url,thumbnail_url,media_type,timestamp,permalink}'
      ].join(',');

      graphCall(
        this.#getRequestUrl(`${commentId}/${endpoint}?fields=${fields}`, socialProfile),
        { message },
        { method: 'post', token: this.#getGraphAccessToken(socialProfile) }
      )
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  /**
   * Deletes a specified Instagram comment.
   */
  deleteComment(commentId: string, params = {}, socialProfile: SocialProfileModel): Promise<void> {
    return new RSVP.Promise((resolve, reject) => {
      graphCall(this.#getRequestUrl(commentId, socialProfile), params, {
        method: 'del',
        token: this.#getGraphAccessToken(socialProfile)
      })
        .then(() => resolve())
        .catch((error: InstagramGraphError) => (error ? reject(error) : resolve()));
    });
  }

  /**
   * Send a graph-call request to the Instagram/FB API.

   * @returns Graph request response
   */
  async graphRequest<T = unknown>(
    endpoint: string,
    socialProfile: SocialProfileModel,
    params?: GraphCallParams
  ): Promise<T> {
    const url = this.#getRequestUrl(endpoint, socialProfile);
    return graphCall(url, params, {
      token: this.#getGraphAccessToken(socialProfile),
      backupToken: socialProfile.businessAccountToken
    });
  }

  #getGraphAccessToken(socialProfile: SocialProfileModel): Maybe<string> {
    const { businessAccountToken, instagramLoginToken } = socialProfile;

    if (businessAccountToken) {
      return this.auth.currentUserModel.facebookToken ?? businessAccountToken ?? '';
    } else if (this.auth.currentAccount?.rolloutInstagramLogin && instagramLoginToken) {
      return instagramLoginToken;
    }

    return '';
  }

  #getRequestUrl(endpoint: string, socialProfile?: SocialProfileModel): string {
    if (socialProfile?.isInstagramLogin) {
      return `https://graph.instagram.com${cleanUrl(endpoint)}`;
    }
    return endpoint;
  }
}

function cleanUrl(_url: string): string {
  let url = _url.trim();

  // Adds leading slash if needed
  if (url.charAt(0) !== '/' && url.substr(0, 4) !== 'http') url = '/' + url;

  return url;
}

declare module '@ember/service' {
  interface Registry {
    'instagram-graph': InstagramGraphService;
  }
}
